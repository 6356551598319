<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <van-cell-group>
        <van-cell
            v-for="contract in contractList"
            :title="contract.contractTitle"
            is-link
            @click="hetongurl(contract.contractContent)"
        />
      </van-cell-group>
    </page-container>
  </div>
</template>

<script>
import { myAgreementUrl } from '@/api/user';
import { Dialog } from 'vant';

export default {
  name: 'EAgreementList',
  data() {
    return {
      contractList: []
    };
  },
  created() {
    myAgreementUrl().then(res => {
      this.contractList = Array.isArray(res) ? res : [];
    });
  },
  methods: {
    hetongurl(iframeUrl) {
      Dialog.confirm({
        title: '是否查看',
      }).then(() => {
        this.$router.push({ path: '/agreement', query: { iframeUrl } });
      }).catch(() => {
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>